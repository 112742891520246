import React from "react";
import {
  BannerPage,
  ButtonLink,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../components";

import { graphql, useStaticQuery } from "gatsby";
import { Row, Col } from "antd";
import { Colors } from "../components";
import Img from "gatsby-image";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Blogs = () => {
  const data = useStaticQuery(graphql`
    query {
      allBlogsJson {
        nodes {
          title
          desc
          slug
          titleImagePath {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const blogs = data.allBlogsJson.nodes;
  return (
    <Layout>
      <SEO title="Blogs" description={"Browse through our blogs"} />
      <BannerPage title={"Blogs"} subTitle={"Browse through our blogs"} />
      <ContainerContent style={{ backgroundColor: Colors.LightGray }}>
        <ContainerContentFixed>
          <Row gutter={[8, 8]}>
            {blogs.map(b => (
              <Col md={12} xs={24} key={b.slug}>
                <div
                  style={{
                    backgroundColor: Colors.White,
                  }}
                >
                  <InternalLink url={b.slug}>
                    {b.titleImagePath && (
                      <Img fluid={b.titleImagePath.childImageSharp.fluid} />
                    )}
                    <div style={{ padding: "3rem 2rem" }}>
                      <div style={{ height: "120px", overflow: "hidden" }}>
                        <h2
                          style={{ fontWeight: 400, color: Colors.DarkGreen }}
                        >
                          {b.title}
                        </h2>
                        {/*<Tag title={"Published on"}>{b.publishDate}</Tag>*/}

                        <p style={{ marginTop: "16px", color: Colors.Gray }}>
                          {b.desc}
                        </p>
                      </div>
                      <ButtonLink
                        style={{ marginLeft: "-16px", marginTop: "16px" }}
                      >
                        Read more
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ marginLeft: "8px" }}
                        />
                      </ButtonLink>
                    </div>
                  </InternalLink>
                </div>
              </Col>
            ))}
          </Row>
        </ContainerContentFixed>
      </ContainerContent>
      <section>
        <ContainerCta
          title={"Your future happiness is waiting"}
          subTitle={"Start building your custom Grandeur Home"}
          secondaryLink={InternalLinkTypes.GetStarted}
          secondaryText={"Get Started"}
        />
      </section>
    </Layout>
  );
};

export default Blogs;
